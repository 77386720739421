import React from "react";
import "../assets/End.scss";
const End = () => {
  return (
    <div id="end-root">
      <p>:)</p>
      <h1>amazingefren</h1>
    </div>
  );
};

export default End;
